<template>
   <div>
      <com-page-main>
         <el-row :gutter="10">
            <el-col :span="20">
               <el-input
                  v-model="k"
                  placeholder="请输入内容"
                  v-on:keyup.enter.native="btnSearch"
               ></el-input>
            </el-col>
            <el-col :span="2">
               <el-button icon="el-icon-search" @click="btnSearch">
                  搜索
               </el-button>
            </el-col>
            <el-col :span="2">
               <el-button
                  :icon="`el-icon-arrow-${show_senior ? 'up' : 'down'}`"
                  @click="show_senior = !show_senior"
               >
                  高级搜索
               </el-button>
            </el-col>
         </el-row>
         <div v-if="show_senior" class="senior_search">
            <el-row :gutter="6">
               <el-col :span="10">
                  <div class="input_item">
                     <el-cascader
                        v-model="value"
                        label="选择分类"
                        placeholder="选择分类"
                        :options="options"
                        clearable
                        style="width: 380px;"
                        :props="{ checkStrictly: true }"
                        @change="handleChange"
                     ></el-cascader>
                  </div>
               </el-col>
               <el-col :span="4">
                  <div class="input_item">
                     <el-date-picker
                        v-model="value1"
                        format="yyyy年MM月dd日"
                        value-format="yyyy-MM-dd"
                        type="daterange"
                        style="width: 560px;"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                     ></el-date-picker>
                  </div>
               </el-col>
            </el-row>
            <el-row :gutter="10">
               <el-col>
                  <div class="input_item">
                     <el-row :gutter="2">
                        <el-col :span="3">
                           <span>包含词组:</span>
                        </el-col>
                        <el-col :span="21">
                           <el-input
                              v-model="contain"
                              placeholder="请输入包含词组"
                           ></el-input>
                        </el-col>
                     </el-row>
                  </div>
               </el-col>
               <el-col>
                  <div class="input_item">
                     <el-row :gutter="2">
                        <el-col :span="3">
                           <span>不包含词组:</span>
                        </el-col>
                        <el-col :span="21">
                           <el-input
                              v-model="not_contain"
                              placeholder="请输入不包含词组"
                           ></el-input>
                        </el-col>
                     </el-row>
                  </div>
               </el-col>
               <el-col>
                  <div class="input_item">
                     <el-row :gutter="2">
                        <el-col :span="3">
                           <span>排序方式：</span>
                        </el-col>
                        <el-col :span="21">
                           <el-radio v-model="sort" label="0">
                              按相关度顺序
                           </el-radio>
                           <el-radio v-model="sort" label="1">
                              按发布时间顺序
                           </el-radio>
                        </el-col>
                     </el-row>
                  </div>
               </el-col>
               <el-col>
                  <div class="input_item">
                     <el-row :gutter="2">
                        <el-col :span="3">
                           <span>搜索位置：</span>
                        </el-col>
                        <el-col :span="21">
                           <el-radio v-model="point" label="0">
                              全部
                           </el-radio>
                           <el-radio v-model="point" label="1">
                              标题
                           </el-radio>
                           <el-radio v-model="point" label="2">
                              正文
                           </el-radio>
                        </el-col>
                     </el-row>
                  </div>
               </el-col>
            </el-row>
         </div>
         <com-new-list
            ref="new_list"
            :url="`post?k=${encodeURI(k)}`"
            show-date
            size="medium"
            pagination
         ></com-new-list>
      </com-page-main>
   </div>
</template>

<script>
import ComPageMain from "@/components/PageMain";
import ComNewList from "@/components/NewList";
import {
   Input,
   Radio,
   Cascader,
   Row,
   Col,
   Button,
   DatePicker,
} from "element-ui";
import { http } from "@/http";

export default {
   name: "PageSearch",
   components: {
      ComNewList,
      ComPageMain,
      [Input.name]: Input,
      [Radio.name]: Radio,
      [Cascader.name]: Cascader,
      [Row.name]: Row,
      [Col.name]: Col,
      [Button.name]: Button,
      [DatePicker.name]: DatePicker,
   },
   data() {
      return {
         options: [],
         value: "",
         value1: null,
         k: this.$route.params.k,
         show_senior: false,
         sort: "0",
         point: "0",
         contain: "",
         not_contain: "",
      };
   },
   watch: {
      $route(newValue) {
         this.k = newValue.params.k;
         this.$refs.new_list.url = `post?k=${encodeURI(this.k)}`;
         this.$refs.new_list.getData();
      },
   },
   created() {
      this.getCategories();
   },
   methods: {
      handleChange(e) {
         this.value = e[e.length - 1];
      },
      getCategories() {
         http
            .get("categorie/tree")
            .then((res) => {
               this.options = res.data;
            })
            .then(() => {
               this.loading = false;
            });
      },
      btnSearch() {
         this.$router.replace({ name: "search", params: { k: this.k } });
         // let url = `post?k=${encodeURI(this.k)}`;

         // if (this.value > 0) {
         //    url += `&c=${this.value}`;
         // }

         // if (this.value1 != null) {
         //    url += `&s=${this.value1[0]}&e=${this.value1[1]}`;
         // }

         // url += `&sort=${this.sort}&point=${this.point}&contain=${this.contain}&not_contain=${this.not_contain}`;

         // this.$refs.new_list.url = url;
         // this.$refs.new_list.getData();
      },
   },
};
</script>

<style lang="scss" scoped>
.senior_search {
   margin: 0 auto;
   width: 80%;
}

.senior_search .input_item {
   line-height: 45px;
   margin: 8px 0;
   font-size: 16px;
   color: #a1a1a1;
}

.senior_search .input_item span {
   padding-right: 5px;
   float: right;
}
.senior_search .input_item input {
   border: 1px solid #a1a1a1;
   padding: 6px;
   width: 100%;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
   border-color: var(--color-main);
}

.el-radio__input.is-checked + .el-radio__label {
   color: var(--color-base);
}
</style>
